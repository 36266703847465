import axios from "axios";

let userInfo = null
let checked = false;
export default {
    userInfo,
    checked,
    async checkUserInfo() {
        if (!checked) {
            await axios.get('/api/system/user/getUserInfo')
                .then(
                    response => {
                        checked = true
                        if (response.data.code !== 200) {
                            this.userInfo = null
                        }
                        this.userInfo = response.data.data
                    }).catch(error => {
                    checked = true
                })
        }
    }
};