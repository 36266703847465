import Vue from 'vue'
import VueRouter from 'vue-router'
import User from "@/store/userInfo";
import axios from 'axios'

Vue.use(VueRouter)
Vue.use(User)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior() {
        return {x: 0, y: 0}
    },
    routes: [
        {
            path: '/',
            name: 'home',
            component: () => import('@/views/Home/Home.vue'),
            meta: {
                pageTitle: '主页',
                breadcrumb: [
                    {
                        text: '主页',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/settings',
            name: 'settings',
            component: () => import('@/views/Accounts/Settings/AccountSetting'),
            meta: {
                pageTitle: '账号设置',
                auth: true,
                breadcrumb: [
                    {
                        text: '账号设置',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/stock/:stockCode',
            name: 'stock',
            component: () => import('@/views/Stock/StockInfo'),
            meta: {
                pageTitle: '股票详情',
                breadcrumb: [
                    {
                        text: '股票详情',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/calendar',
            name: 'calendar',
            component: () => import('@/views/Calendar/Calendar'),
            meta: {
                pageTitle: '新股日历',
                breadcrumb: [
                    {
                        text: '新股日历',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/hot',
            name: 'hot',
            component: () => import('@/views/Home/HotDiscussions'),
            meta: {
                pageTitle: '热议',
                breadcrumb: [
                    {
                        text: '热议',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/search',
            name: 'search',
            component: () => import('@/views/Search/Search'),
            meta: {
                pageTitle: '筛选器',
                breadcrumb: [
                    {
                        text: '筛选器',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/selected',
            name: 'selected',
            component: () => import('@/views/User/Selected/UserSelectedStocks'),
            meta: {
                pageTitle: '自选股',
                auth: true,
                breadcrumb: [
                    {
                        text: '自选股',
                        active: true,
                    },
                ],
            },
        },
        {
            path: '/login',
            name: 'login',
            component: () => import('@/views/Accounts/Auth/Login.vue'),
            meta: {
                layout: 'full',
                logged: true,
            },
        },
        {
            path: '/register',
            name: 'register',
            component: () => import('@/views/Accounts/Auth/Register.vue'),
            meta: {
                layout: 'full',
                logged: true,
            },
        },
        {
            path: '/forgetPassword',
            name: 'forgetPassword',
            component: () => import('@/views/Accounts/Auth/ForgetPassword.vue'),
            meta: {
                layout: 'full',
                logged: true,
            },
        },
        {
            path: '/error-404',
            name: 'error-404',
            component: () => import('@/views/error/Error404.vue'),
            meta: {
                layout: 'full',
            },
        },
        {
            path: '*',
            redirect: 'error-404',
        },
    ],
})


//拦截器
router.beforeEach(async (to, from, next) => {
    await User.checkUserInfo()
    if (to.matched.some(record => record.meta.auth) && to.meta.auth) {
        let user = User.userInfo;
        if (user) {
            next()
        } else {
            next({
                name: 'login',
                params: {
                    redirect: to.name
                }
            })
        }
    }
    if (to.matched.some(record => record.meta.logged) && to.meta.logged) {
        let user = User.userInfo;
        if (user) {
            next({
                path: '/',
            })
        }
    }
    next();
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById('loading-bg')
    if (appLoading) {
        appLoading.style.display = 'none'
    }
})

export default router
